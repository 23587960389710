import { Button, Paper, SortDirection, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from "@mui/material";
import { useState, useRef, useMemo } from "react";
import { CampaignData, CaptureData, ClientData, ClientsCapturesCampaignsData, WorkflowOutcome } from "../../async/ClientConfigData";

interface PipelinePublishRowData {
    campaign: CampaignData;
    foundCapture: CaptureData | undefined;
    foundClient: ClientData | undefined;
    foundExecution: WorkflowOutcome | undefined;
};

interface PipelinePublishRowProps {
    campaign: PipelinePublishRowData;
    search: string;
    openDialog: (value: any) => void;
};

const getHighlightedText = (text: string | undefined, search: string) => {
    if (text && search.length > 0) {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return <span> {
            parts.map((part, i) =>
                <span key={i} style={part.toLowerCase() === search.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                    {part}
                </span>)
        }
        </span>;
    } else {
        return <span>{text}</span>
    }
};

const rowIsVisible = (texts: (string | undefined)[], search: string) => {
    return search.length === 0 || texts.some((text) => {
        return text !== undefined && text.includes(search);
    })
};

const PipelinePublishRow = ({ campaign, search, openDialog }: PipelinePublishRowProps) => {
    const publishOnClick = () => {
        openDialog({
            root: {
                client_code: campaign.foundClient?.Code,
                client_name: campaign.foundClient?.Name,
                client_id: campaign.foundClient?.Id?.toString(),
                campaign: campaign.campaign.CampaignName,
                cycle: campaign.campaign.Cycle?.toString(),
                capture_id: campaign.foundCapture?.Id?.toString(),
                capture_name: campaign.foundCapture?.Name
            }
        })
    };

    const cycle = campaign.campaign.Cycle !== undefined && campaign.campaign.Cycle !== null ? campaign.campaign.Cycle.toString() : "";
    const captureId = campaign.foundCapture?.Id?.toString() ?? ""

    if (rowIsVisible([campaign.foundClient?.Name, cycle, campaign.foundCapture?.Name, captureId, campaign.campaign.CampaignName, campaign.campaign.CreateDate], search)) {
        return <TableRow>
            <TableCell component="td">{getHighlightedText(campaign.foundClient?.Name, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(cycle, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(campaign.foundCapture?.Name, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(campaign.campaign.CampaignName, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(campaign.campaign.CreateDate, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(campaign.foundCapture?.CaptureFinish, search)}</TableCell>
            <TableCell component="td">{getHighlightedText(campaign.foundExecution?.start_date, search)}</TableCell>
            <TableCell component="td">
                <Button onClick={publishOnClick}>Publish</Button>
            </TableCell>
        </TableRow>;
    } else {
        return <></>;
    }
};

interface ClientConfigTableProps {
    clientsCampaignsCaptures: ClientsCapturesCampaignsData;
    openDialog: (value: any) => void;
};

const ascDateSort = (a: Date, b: Date) => a.getTime() - b.getTime();
const descDateSort = (a: Date, b: Date) => b.getTime() - a.getTime();
const ascStrSort = (a: string, b: string) => a.localeCompare(b);
const descStrSort = (a: string, b: string) => b.localeCompare(a);

const comparators = {
    client_name: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.foundClient?.Name ? a.foundClient.Name : "";
            const bStr = b.foundClient?.Name ? b.foundClient.Name : "";

            return ascStrSort(aStr, bStr);
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.foundClient?.Name ? a.foundClient.Name : "";
            const bStr = b.foundClient?.Name ? b.foundClient.Name : "";

            return descStrSort(aStr, bStr);
        }
    },
    cycle_num: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            return a.campaign.Cycle - b.campaign.Cycle;
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            return b.campaign.Cycle - a.campaign.Cycle;
        }
    },
    capture_name: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.foundCapture?.Name ? a.foundCapture?.Name : "";
            const bStr = b.foundCapture?.Name ? b.foundCapture?.Name : "";

            return ascStrSort(aStr, bStr);
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.foundCapture?.Name ? a.foundCapture?.Name : "";
            const bStr = b.foundCapture?.Name ? b.foundCapture?.Name : "";

            return descStrSort(aStr, bStr);
        }
    },
    campaign_name: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.campaign.CampaignName ? a.campaign.CampaignName : "";
            const bStr = b.campaign.CampaignName ? b.campaign.CampaignName : "";

            return ascStrSort(aStr, bStr);
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStr = a.campaign.CampaignName ? a.campaign.CampaignName : "";
            const bStr = b.campaign.CampaignName ? b.campaign.CampaignName : "";

            return descStrSort(aStr, bStr);
        }
    },
    created_date: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            return ascDateSort(new Date(a.campaign.CreateDate), new Date(b.campaign.CreateDate));
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            return descDateSort(new Date(a.campaign.CreateDate), new Date(b.campaign.CreateDate));
        }
    },
    capture_finish_date: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStartDate = a.foundCapture?.CaptureFinish !== undefined ? new Date(a.foundCapture?.CaptureFinish) : new Date(0);
            const bStartDate = b.foundCapture?.CaptureFinish !== undefined ? new Date(b.foundCapture?.CaptureFinish) : new Date(0);
            return ascDateSort(aStartDate, bStartDate);
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStartDate = a.foundCapture?.CaptureFinish !== undefined ? new Date(a.foundCapture?.CaptureFinish) : new Date(0);
            const bStartDate = b.foundCapture?.CaptureFinish !== undefined ? new Date(b.foundCapture?.CaptureFinish) : new Date(0);
            return descDateSort(aStartDate, bStartDate);
        }
    },
    last_executed: {
        asc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStartDate = a.foundExecution?.start_date !== undefined ? new Date(a.foundExecution?.start_date) : new Date(0);
            const bStartDate = b.foundExecution?.start_date !== undefined ? new Date(b.foundExecution?.start_date) : new Date(0);
            return ascDateSort(aStartDate, bStartDate);
        },
        desc: (a: PipelinePublishRowData, b: PipelinePublishRowData) => {
            const aStartDate = a.foundExecution?.start_date !== undefined ? new Date(a.foundExecution?.start_date) : new Date(0);
            const bStartDate = b.foundExecution?.start_date !== undefined ? new Date(b.foundExecution?.start_date) : new Date(0);
            return descDateSort(aStartDate, bStartDate);
        }
    }
} as any;

const headCells = [
    {
        id: 'client_name',
        label: 'Client Name',
    },
    {
        id: 'cycle_num',
        label: 'Cycle',
    },
    {
        id: 'capture_name',
        label: 'Capture Name',
    },
    {
        id: 'campaign_name',
        label: 'Campaign Name',
    },
    {
        id: 'created_date',
        label: 'Created Date',
    },
    {
        id: 'capture_finish_date',
        label: 'Capture Finish Date',
    },
    {
        id: 'last_executed',
        label: 'Last Executed Date',
    },
    {
        id: 'actions',
        label: 'Actions',
    }
];

const PipelinePublishTable = ({ clientsCampaignsCaptures, openDialog }: ClientConfigTableProps) => {
    const [order, setOrder] = useState<SortDirection>('desc');
    const [orderBy, setOrderBy] = useState('created_date');

    const [search, setSearch] = useState("");
    const ref = useRef<HTMLInputElement>(null);

    const onSearchChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && ref.current !== null) {
            setSearch(ref.current.value);
        }
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const augmentedCampaigns = useMemo(() => {
        return clientsCampaignsCaptures.campaigns.map((campaign) => {
            const foundCapture = clientsCampaignsCaptures.captures.find((capture: CaptureData) => {
                return campaign.CaptureId === capture.Id;
            });

            const foundClient = clientsCampaignsCaptures.clients.find((client: ClientData) => {
                return foundCapture !== undefined && foundCapture.ClientId === client.Id;
            });

            const foundExecution = clientsCampaignsCaptures.publishExecutionDescriptions.find((publishExecutionDescription) => {
                return publishExecutionDescription.client_id === foundCapture?.ClientId.toString() &&
                    publishExecutionDescription.capture_id === campaign.CaptureId &&
                    publishExecutionDescription.cycle === campaign.Cycle.toString();
            });
            return {
                campaign,
                foundCapture,
                foundClient,
                foundExecution
            };
        });
    }, [clientsCampaignsCaptures]);

    const campaignsCopy = useMemo(
        () =>
            [...augmentedCampaigns].sort(comparators[orderBy][order as string]),
        [order, orderBy, augmentedCampaigns]
    );

    return <>
        <div className='fugro-pipeline-publish-search'>
            <TextField inputRef={ref} id="search" label="Search" variant="outlined" onBlur={onSearchChange} onKeyDown={onSearchKeyDown} />
        </div>
        <TableContainer sx={{ flexShrink: 0 }} component={Paper}>
            <Table sx={{
                "& .MuiTableRow-root:hover": {
                    backgroundColor: "action.hover"
                }
            }} stickyHeader>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : undefined} >
                                { comparators.hasOwnProperty(headCell.id) ? <TableSortLabel active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order as any : 'asc'}
                                    onClick={createSortHandler(headCell.id)} >
                                    {headCell.label}
                                </TableSortLabel> : headCell.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        campaignsCopy.map((campaign: PipelinePublishRowData, index: number) => {
                            return <PipelinePublishRow key={index}
                                campaign={campaign}
                                search={search}
                                openDialog={openDialog} />;
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export {
    PipelinePublishTable
}