
import { CircularProgress, Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { subscribeToExecutionChanges, unsubscribeToExecutionChanges } from '../../async/ClientConfigData';
import { IToast } from './Toast';
import { DATAHUB_API_URL, PIPELINE_PUBLISH_SFN, PIPELINE_TPLY_SFN } from '../../util/GlobalVars';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { callWithAuthToken } from '../../auth/AuthApiCall';
import { InteractionStatus } from '@azure/msal-browser';

interface ExecutionTableProps {
  setToast: (toastProps: IToast) => void;
}

export const enum SubscriptionChoice {
  Execution,
  Workflow
}

interface ExecutionRowProps {
  emailAddress: string;
  stateMachineArn: string;
  setToast: (toastProps: IToast) => void;
  setSubscribedArns: (arns: string[]) => void;
  subscribedArns: string[] | null;
}

const SubscriptionRow = ({ emailAddress, stateMachineArn, subscribedArns, setSubscribedArns, setToast }: ExecutionRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const data = {
    workflowArn: stateMachineArn,
    userId: emailAddress
  };

  const stateMachineName = stateMachineArn.split("stateMachine:")[1];

  const getSubscribe = (subscribedArns: string[]) => () => {
    setLoading(true);
    subscribeToExecutionChanges(data)
      .then(() => {
        setToast({ open: true, messageType: 'success', text: 'Subscription successful' });
        setSubscribedArns([data.workflowArn].concat(...subscribedArns));
      }, () => {
        setToast({ open: true, messageType: 'error', text: 'Unable to subscribe to this event' })
      }).finally(() => {
        setLoading(false);
      });
  };

  const getUnsubscribe = (subscribedArns: string[]) => () => {
    setLoading(true);
    unsubscribeToExecutionChanges(data)
      .then(() => {
        setToast({ open: true, messageType: 'success', text: 'Unsubscription successful' });
        setSubscribedArns(subscribedArns.filter((arn) => arn !== stateMachineArn));
      }, () => {
        setToast({ open: true, messageType: 'error', text: 'Unable to unsubscribe to this event' })
      }).finally(() => {
        setLoading(false);
      });
  };

  const getNotificationIcon = (stateMachineArn: string, subscribedArns: string[]) => {
    if (subscribedArns.indexOf(stateMachineArn) > -1) {
      return <NotificationsIcon sx={{ cursor: 'pointer' }} onClick={getUnsubscribe(subscribedArns)}></NotificationsIcon>
    } else {
      return <NotificationsNoneIcon sx={{ cursor: 'pointer' }} onClick={getSubscribe(subscribedArns)}></NotificationsNoneIcon>
    }
  };

  return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell>{stateMachineName}</TableCell>
    <TableCell>{loading || subscribedArns === null ? <CircularProgress size="1rem" /> : getNotificationIcon(stateMachineArn, subscribedArns)}</TableCell>
  </TableRow>;
};

const SubscriptionsTable = ({ setToast }: ExecutionTableProps) => {
  const [subscribedArns, setSubscribedArns] = useState<string[] | null>(null);

  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const emailAddress = accounts[0].username;

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && subscribedArns === null) {
      callWithAuthToken(`${DATAHUB_API_URL}/subscribe?userId=${emailAddress}`, true, 'GET', undefined)
        .then((res) => {
          setSubscribedArns(res);
        })
    }
  }, [emailAddress, isAuthenticated, inProgress, subscribedArns]);

  return <Paper>
    <TableContainer className='fugro-executions-execution-table'>
      <Table sx={{
        "& .MuiTableRow-root:hover": {
          backgroundColor: "action.hover"
        }
      }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">Workflow</TableCell>
            <TableCell component="th"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            <SubscriptionRow emailAddress={emailAddress} stateMachineArn={PIPELINE_PUBLISH_SFN} setSubscribedArns={setSubscribedArns} subscribedArns={subscribedArns} setToast={setToast} />
            <SubscriptionRow emailAddress={emailAddress} stateMachineArn={PIPELINE_TPLY_SFN} setSubscribedArns={setSubscribedArns} subscribedArns={subscribedArns} setToast={setToast} />
          </>
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>;
}

export {
  SubscriptionsTable
}