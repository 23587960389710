import { CLIENT_ID, AUTHORITY, REDIRECT_URL } from "../util/GlobalVars";

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID as string,
    authority: AUTHORITY as string,
    redirectUri: REDIRECT_URL as string
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

export const externalRequest = {
  scopes: [`api://${CLIENT_ID}/aws`]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};