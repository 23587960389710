 
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { OrchestrationExecutionsSummaryData, WorkflowOutcome } from '../../async/ClientConfigData';
import { toDateTimeFormat, toHHMMSS } from '../../util/Duration';
import { getExecutionUrl } from '../../util/GlobalVars';
import { useNavigate } from 'react-router-dom';
import { root } from '../../routes/Routes';

interface ExecutionTableProps {
  executions: WorkflowOutcome[];
  orchestrationExecutionsSummaryData: OrchestrationExecutionsSummaryData;
}

export const enum SubscriptionChoice {
  Execution,
  Workflow
}

interface ExecutionRowProps {
  execution: WorkflowOutcome;
  orchestrationExecutionsSummaryData: OrchestrationExecutionsSummaryData;
}

const ExecutionRow = ({ execution, orchestrationExecutionsSummaryData }: ExecutionRowProps) => {
  const navigate = useNavigate();

  const stateMachineName = execution.state_machine_arn.split("stateMachine:")[1];
  const executionName = execution.execution_arn.split(`execution:${stateMachineName}:`)[1];

  const startDate = execution.start_date !== undefined && execution.start_date !== null ? new Date(execution.start_date) : undefined;
  const stopDate = execution.stop_date !== undefined && execution.stop_date !== null ? new Date(execution.stop_date) : undefined;
  const duration = startDate !== undefined && stopDate !== undefined ? toHHMMSS(stopDate.getTime() - startDate.getTime()) : '';

  const failed = execution.status === 'FAILED';
  const failedStyle = failed ? { backgroundColor: 'error.light' } : {};
  const status = failed || execution.status === 'SUCCEEDED' ? (execution.status === 'FAILED' ? `${execution.status} 🚫` : `${execution.status} ✅`) :
    execution.status;

  const clientId = parseInt(execution.client_id);
  const captureId = parseInt(execution.capture_id);
  const cycle = execution.cycle;

  const foundClient = orchestrationExecutionsSummaryData.clients.find((client) => { return client.Id === clientId });

  const foundCapture = orchestrationExecutionsSummaryData.captures.find((capture) => { return capture.Id === captureId });

  const onClick = () => {
    navigate({
      pathname: root,
      search: `?execution=${execution.execution_arn}`
    });
  };

  return <><TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ...failedStyle }}
    onClick={onClick}>
    <TableCell component="th" scope="row">{stateMachineName}</TableCell>
    <TableCell><a href={getExecutionUrl(stateMachineName, executionName)}>{executionName}</a></TableCell>
    <TableCell>{status}</TableCell>
    <TableCell>{startDate !== undefined ? toDateTimeFormat(startDate) : startDate}</TableCell>
    <TableCell>{stopDate !== undefined ? toDateTimeFormat(stopDate) : stopDate}</TableCell>
    <TableCell>{duration}</TableCell>
    <TableCell>{foundClient?.Name}</TableCell>
    <TableCell>{cycle}</TableCell>
    <TableCell>{foundCapture?.Name}</TableCell>
  </TableRow>
  </>;
};

const ExecutionSummaryHistoryTable = ({ executions, orchestrationExecutionsSummaryData }: ExecutionTableProps) => {
  return <>
    <TableContainer component={Paper}>
      <Table sx={{
        "& .MuiTableRow-root:hover": {
          backgroundColor: "action.hover"
        }
      }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th">Workflow</TableCell>
            <TableCell component="th">Execution</TableCell>
            <TableCell component="th">Status</TableCell>
            <TableCell component="th">Start Date</TableCell>
            <TableCell component="th">Stop Date</TableCell>
            <TableCell component="th">Duration</TableCell>
            <TableCell component="th">Client</TableCell>
            <TableCell component="th">Cycle</TableCell>
            <TableCell component="th">Capture</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            executions.map((execution: WorkflowOutcome, index: number) => {
              return <ExecutionRow key={index} execution={execution} orchestrationExecutionsSummaryData={orchestrationExecutionsSummaryData} />;
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>;
};

export {
  ExecutionSummaryHistoryTable
};