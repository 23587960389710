import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/layout/App';
import reportWebVitals from './reportWebVitals';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  BrowserRouter
} from "react-router-dom";
import { msalConfig } from "./auth/authConfig";
import { defineCustomElements } from '@fugro/fugro.web.common';
import { setAssetPath } from '@fugro/fugro.web.common';

// Please see documentation of fugro.web.common to know more about these methods
setAssetPath(document.baseURI + 'fugro.web.common/');
defineCustomElements();

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
