import { FormControlLabel, FormControl, Radio, RadioGroup, Autocomplete, Skeleton, TextField } from "@mui/material";
import { getAndSetLayers } from "../../async/ClientConfigData";
import { IToast } from "./Toast";
import { useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export interface PipelinePublishDialogProps {
    value: any;
    setValue: (attribute_name: string, value: any) => void;
    setRootValue: (attribute_name: string, value: any) => void;
    setToast: (toastProps: IToast) => void;
}

export const VECTORS_REGEX = "(?!.*raster).*";

const getLayerNames = (layers: any | null, clientName: string | null | undefined): string[] => {
    return layers !== null && clientName !== null && clientName !== undefined && layers[clientName]?.layers !== undefined ? layers[clientName]?.layers : [];
};

export const stringifyValue = (value: string[] | undefined) => `"${JSON.stringify(value !== undefined ? value : [])}"`;

const PipelinePublishDialogLayers = ({ value, setValue, setRootValue, setToast }: PipelinePublishDialogProps) => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    const [layerFilterRadioValue, setLayerFilterRadioValue] = useState('vectors');
    const [layerSelectionValues, setLayerSelectionOptions] = useState<string[]>([]);
    const [customLayerSelectionValue, setCustomLayerSelectionValue] = useState('');

    const [layers, setLayers] = useState<any | null>(null);

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && layers === null && layerFilterRadioValue === 'selection') {
            getAndSetLayers(setLayers, setToast)
        }
    }, [isAuthenticated, inProgress, layers, setLayers, setToast, layerFilterRadioValue]);

    const getLayerValue = (layerFilterRadioValue: string) => {
        return ({
        'vectors': [VECTORS_REGEX],
        'rasters': [".*raster.*"],
        'selection': layerSelectionValues,
        'custom': [customLayerSelectionValue]
      })[layerFilterRadioValue];
    };

    const handlerLayerSelectionChange = (event: any, newValue: string[]) => {
        setLayerSelectionOptions(newValue);
        setRootValue('layer_filter', stringifyValue(newValue));
    };

    const handleSetCustomLayerSelectionChange = (event: any) => {
        const value = event.target.value
        setCustomLayerSelectionValue(value);
        setRootValue('layer_filter', stringifyValue([value]));
    };

    const handleLayerFilterRadioChange = (event: any) => {
        const value = event.target.value
        setLayerFilterRadioValue(value);
        setRootValue('layer_filter', stringifyValue(getLayerValue(value)));
    };

    const layerSelectionOptions = getLayerNames(layers, value.root.client_name);

    return <>
            <FormControl>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={layerFilterRadioValue}
                    onChange={handleLayerFilterRadioChange}
                >
                    <FormControlLabel value="vectors" control={<Radio />} label="Vector Layers" />
                    <FormControlLabel value="rasters" control={<Radio />} label="Raster Layers" />
                    <FormControlLabel value="selection" control={<Radio />} label="Selection" />
                    <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                </RadioGroup>
            </FormControl>
        {
            layerFilterRadioValue === 'selection' ? <>
                {
                    layers !== null ? <Autocomplete
                        multiple
                        id="tags-standard"
                        options={layerSelectionOptions}
                        value={layerSelectionValues}
                        onChange={handlerLayerSelectionChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Layers"
                            />
                        )}
                    /> : <Skeleton></Skeleton>
                }
            </> : <></>
        }
        {
            layerFilterRadioValue === 'custom' ? <>
                {
                    <TextField
                        label="Custom"
                        placeholder="Custom Layer String/Regex"
                        value={customLayerSelectionValue}
                        onChange={handleSetCustomLayerSelectionChange}
                    />
                }
            </> : <></>
        }
    </>;
};

export {
    PipelinePublishDialogLayers
}