import './App.css';
import { FugroSideNavMenu, FugroSideNavMenuItem } from '@fugro/fugro.web.common.react';
import '../../../node_modules/@fugro/fugro.web.common/dist/fugro-web-common/fugro-web-common.css';
import { MsalAuthenticationTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { OrchestrationExecutions } from './OrchestrationExecutions';
import { loginRequest } from "../../auth/authConfig";
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import * as AppRoutes from '../../routes/Routes'; 
import { InteractionRequiredAuthError, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Toast, IToast } from '../components/Toast';
import { ClientPublications } from './ClientPublications';
import { PipelinePublish } from './PipelinePublish';
import { Subscriptions } from './Subscriptions';

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e);
  });
};

function App() {
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);
  const [toast, setToast] = useState<IToast>({ open: false, messageType: 'success', text: '' });

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup);
    }
  }, [error, login]);

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();


  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} loadingComponent={() => <LinearProgress />}
      authenticationRequest={loginRequest}>
      <div className='App'>
        <FugroSideNavMenu
          user-name={accounts.length > 0 ? accounts[0].name : ''}
          onFugroSignOutClick={() => handleLogout(instance)}>
          <FugroSideNavMenuItem label="Last Publications" onClick={() => navigate(AppRoutes.root)}></FugroSideNavMenuItem>
          <FugroSideNavMenuItem label="Client Publications Summary" onClick={() => navigate(AppRoutes.clientPublications)}></FugroSideNavMenuItem>
          <FugroSideNavMenuItem label="Pipeline Publish" onClick={() => navigate(AppRoutes.pipelinePublish)}></FugroSideNavMenuItem>
          <FugroSideNavMenuItem label="Subscriptions" onClick={() => navigate(AppRoutes.subscriptions)}></FugroSideNavMenuItem>
        </FugroSideNavMenu>
        <div className='fugro-content-body'>
          <Toast toastAttrs={toast} setToast={setToast}></Toast>
          <Routes>
            <Route path={AppRoutes.root}>
              <Route index element={<OrchestrationExecutions setToast={setToast} />} />
              <Route path={AppRoutes.clientPublications} element={<ClientPublications setToast={setToast} />} />
              <Route path={AppRoutes.pipelinePublish} element={<PipelinePublish setToast={setToast} />} />
              <Route path={AppRoutes.subscriptions} element={<Subscriptions setToast={setToast} />} />
            </Route>
          </Routes>
        </div>
      </div>
    </MsalAuthenticationTemplate>
  );
}

export default App;
