
import { Button, IconButton, Skeleton, Table, Toolbar, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EnqueuedWorkflowOutcome, deleteEnqueuedExecution, setEnqueuedOrchestrationData, swapEnqueuedExecutions } from '../../async/ClientConfigData';
import { InteractionStatus } from '@azure/msal-browser';
import { IToast } from './Toast';
import { msalInstance } from "../../index";
import { isDatahubAdmin } from "../../auth/Auth";

interface ExecutionTableProps {
  setToast: (toastProps: IToast) => void;
  enqueuedOrchestrationExecutionsData: EnqueuedWorkflowOutcome[] | null;
  setEnqueuedOrchestrationExecutionsData: (orchestrationExecutionsData: EnqueuedWorkflowOutcome[] | null) => void;
}

export const enum SubscriptionChoice {
  Execution,
  Workflow
}

interface EnqueuedRowProps {
  execution: EnqueuedWorkflowOutcome;
  prevExecution: EnqueuedWorkflowOutcome | undefined;
  nextExecution: EnqueuedWorkflowOutcome | undefined;
  loading: boolean;
  isAdmin: boolean;
  setLoading: (newValue: boolean) => void;
  setToast: (toastProps: IToast) => void;
  setEnqueuedOrchestrationExecutionsData: (orchestrationExecutionsData: EnqueuedWorkflowOutcome[] | null) => void;
}

const EnqueuedRow = ({ prevExecution, nextExecution, execution, loading, isAdmin, setLoading, setToast, setEnqueuedOrchestrationExecutionsData }: EnqueuedRowProps) => {
  const stateMachineName = execution.state_machine_arn?.split("stateMachine:")[1];

  const cycle = execution.cycle;

  const deleteOnClick = () => {
    setLoading(true);

    deleteEnqueuedExecution(execution.id).then(() => {
      setEnqueuedOrchestrationExecutionsData(null);
      setLoading(false);
    }).catch((error) => {
      setToast({ open: true, messageType: 'error', text: 'Retrieval of Executions Failed', error });
      setLoading(false);
    });
  };

  const getPrioritizeOnClick = (pExecution: EnqueuedWorkflowOutcome) => () => {
    setLoading(true);

    swapEnqueuedExecutions(pExecution, execution).then(() => {
      setEnqueuedOrchestrationExecutionsData(null);
      setLoading(false);
    }).catch((error) => {
      setToast({ open: true, messageType: 'error', text: 'Retrieval of Executions Failed', error });
      setLoading(false);
    });
  };

  const getDeprioritizeOnClick = (nExecution: EnqueuedWorkflowOutcome) => () => {
    setLoading(true);

    swapEnqueuedExecutions(execution, nExecution).then(() => {
      setEnqueuedOrchestrationExecutionsData(null);
      setLoading(false);
    }).catch((error) => {
      setToast({ open: true, messageType: 'error', text: 'Retrieval of Executions Failed', error });
      setLoading(false);
    });
  };

  return <>
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">{stateMachineName}</TableCell>
      <TableCell>{execution.client_name}</TableCell>
      <TableCell>{cycle}</TableCell>
      <TableCell>{execution.capture_name}</TableCell>
      <TableCell align="right">
        {
          prevExecution !== undefined ? <Button onClick={getPrioritizeOnClick(prevExecution)} disabled={!isAdmin || loading} title={"Prioritize"}>↑</Button> : <></>
        }
        {
          nextExecution !== undefined ? <Button onClick={getDeprioritizeOnClick(nextExecution)} disabled={!isAdmin || loading} title={"Deprioritize"}>↓</Button> : <></>
        }
        <Button onClick={deleteOnClick} disabled={!isAdmin || loading}>Delete</Button>
      </TableCell>
    </TableRow>
  </>;
};

const EnqueuedExecutionTable = ({ setToast, enqueuedOrchestrationExecutionsData, setEnqueuedOrchestrationExecutionsData }: ExecutionTableProps) => {
  const account = msalInstance.getActiveAccount();
  const isAdmin = isDatahubAdmin(account);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && enqueuedOrchestrationExecutionsData === null) {
      setEnqueuedOrchestrationData(setEnqueuedOrchestrationExecutionsData, setToast)
    }
  }, [isAuthenticated, inProgress, enqueuedOrchestrationExecutionsData, setEnqueuedOrchestrationExecutionsData, setToast]);

  const refresh = () => {
    setEnqueuedOrchestrationExecutionsData(null);
  };

  return <>
    {
      enqueuedOrchestrationExecutionsData !== null && enqueuedOrchestrationExecutionsData.length === 0 ? <></> :
        <Paper>
          <Toolbar>
            <Typography sx={{ flex: '1 1 100%' }}variant="h6">
              Queued Executions
            </Typography>
            <Tooltip title="Refresh">
              <IconButton onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <TableContainer>
            <Table sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: "action.hover"
              }
            }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Queued Workflow</TableCell>
                  <TableCell component="th">Client</TableCell>
                  <TableCell component="th">Cycle</TableCell>
                  <TableCell component="th">Capture</TableCell>
                  <TableCell component="th" align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  enqueuedOrchestrationExecutionsData !== null ? <>
                    {
                      enqueuedOrchestrationExecutionsData.map((execution: EnqueuedWorkflowOutcome, index: number) => {
                        const prevExecution = index !== 0 ? enqueuedOrchestrationExecutionsData[index - 1] : undefined;
                        const nextExecution = index !== enqueuedOrchestrationExecutionsData.length - 1 ? enqueuedOrchestrationExecutionsData[index + 1] : undefined;
                        return <EnqueuedRow key={index}
                          prevExecution={prevExecution}
                          nextExecution={nextExecution}
                          execution={execution}
                          loading={loading}
                          isAdmin={isAdmin}
                          setLoading={setLoading}
                          setToast={setToast} setEnqueuedOrchestrationExecutionsData={setEnqueuedOrchestrationExecutionsData} />;
                      })
                    }
                  </> : <TableRow>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    }
  </>;
}

export {
  EnqueuedExecutionTable
}