
import { Button, IconButton, Skeleton, Table, Toolbar, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { setOrchestrationData, WorkflowOutcome } from '../../async/ClientConfigData';
import { InteractionStatus } from '@azure/msal-browser';
import { IToast } from './Toast';
import { toDateTimeFormat, toHHMMSS } from '../../util/Duration';
import { getExecutionUrl } from '../../util/GlobalVars';

interface ExecutionTableProps {
  setToast: (toastProps: IToast) => void;
  orchestrationExecutionsData: WorkflowOutcome[] | null;
  setOrchestrationExecutionsData: (orchestrationExecutionsData: WorkflowOutcome[] | null) => void;
  setExecutionHistory: (executionHistory: null) => void;
  setExecutionDescription: (executionDescription: null) => void;
  setExecutionValidation: (executionValidation: null) => void;
}

export const enum SubscriptionChoice {
  Execution,
  Workflow
}

interface ExecutionRowProps {
  executionUrl: string | null;
  execution: WorkflowOutcome;
  setExecutionHistory: (executionHistory: null) => void;
  setExecutionDescription: (executionDescription: null) => void;
  setExecutionValidation: (executionValidation: null) => void;
  setSearchParams: (params: any) => void;
}

const ExecutionRow = ({ executionUrl, execution, setExecutionHistory, setExecutionDescription, setExecutionValidation, setSearchParams }: ExecutionRowProps) => {

  const stateMachineName = execution.state_machine_arn?.split("stateMachine:")[1];
  const executionName = execution.execution_arn.split(`execution:${stateMachineName}:`)[1];

  const selected = executionUrl === execution.execution_arn;

  const startDate = execution.start_date !== undefined && execution.start_date !== null ? new Date(execution.start_date) : undefined;
  const stopDate = execution.stop_date !== undefined && execution.stop_date !== null ? new Date(execution.stop_date) : undefined;
  const duration = startDate !== undefined && stopDate !== undefined ? toHHMMSS(stopDate.getTime() - startDate.getTime()) : '';

  const selectRowOnClick = () => {
    setExecutionHistory(null);
    setExecutionDescription(null);
    setExecutionValidation(null);
    setSearchParams({ execution: execution.execution_arn as string });
  };

  const failed = execution.status === 'FAILED';
  const failedStyle = failed ? { backgroundColor: 'error.light' } : {};
  const status = execution.status === 'FAILED' || execution.status === 'SUCCEEDED' ? (execution.status === 'FAILED' ? `${execution.status} 🚫` : `${execution.status} ✅`) :
    execution.status;

  const cycle = execution.cycle;

  return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ...failedStyle }}
    onClick={selectRowOnClick}
    selected={selected}
  >
    <TableCell component="th" scope="row">{stateMachineName}</TableCell>
    <TableCell><a href={getExecutionUrl(stateMachineName, executionName)}>{executionName}</a></TableCell>
    <TableCell>{status}</TableCell>
    <TableCell>{startDate !== undefined ? toDateTimeFormat(startDate) : startDate}</TableCell>
    <TableCell>{stopDate !== undefined ? toDateTimeFormat(stopDate) : stopDate}</TableCell>
    <TableCell>{duration}</TableCell>
    <TableCell>{execution.client_name}</TableCell>
    <TableCell>{cycle}</TableCell>
    <TableCell>{execution.capture_name}</TableCell>
  </TableRow>;
};

const writeToClipboard = (el: HTMLElement) => {
  const html = el.outerHTML;
  const type = "text/html";

  const blob = new Blob([html], { type });

  navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
};

const ExecutionTable = ({ setToast, orchestrationExecutionsData, setOrchestrationExecutionsData, setExecutionHistory, setExecutionDescription, setExecutionValidation }: ExecutionTableProps) => {
  const tableEl = useRef<HTMLTableElement>(null);
  const tableBodyEl = useRef<HTMLTableSectionElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const executionUrl = searchParams.get("execution");

  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && orchestrationExecutionsData === null) {
      setOrchestrationData(setOrchestrationExecutionsData, setToast)
    }
  }, [isAuthenticated, inProgress, orchestrationExecutionsData, setOrchestrationExecutionsData, setToast]);

  const onClickCopyAll = () => {
    const table = tableEl.current;
    if (table !== null) {
      writeToClipboard(table);
    }
  };

  const onClickCopyAllWithoutHeaders = () => {
    const table = tableEl.current;
    const tableBody = tableBodyEl.current;
    const parent = table !== null ? table.parentNode : null;
    if (parent !== null && table !== null && tableBody !== null) {
      const tableClone = table.cloneNode(false) as HTMLTableElement;
      const tableBodyClone = tableBody.cloneNode(true) as HTMLTableSectionElement;
      parent.insertBefore(tableClone, table);
      tableClone.appendChild(tableBodyClone);
      writeToClipboard(tableClone);
      parent.removeChild(tableClone);
    }
  };

  const refresh = () => {
    setOrchestrationExecutionsData(null);
  };

  const clearSelectionOnClick = () => {
    setExecutionHistory(null);
    setExecutionDescription(null);
    setExecutionValidation(null);
    setSearchParams({});
  };

  return <Paper>
      <Toolbar>
        <Typography sx={{ flex: '1 1 100%' }} variant="h6">
          Executions
        </Typography>
        {executionUrl !== null && executionUrl !== undefined ? <Button onClick={clearSelectionOnClick}>Clear Selection</Button> : <></>}
        <Tooltip title="Refresh">
          <IconButton onClick={refresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer className='fugro-executions-execution-table'>
        <Table sx={{
          "& .MuiTableRow-root:hover": {
            backgroundColor: "action.hover"
          }
        }} ref={tableEl}>
          <TableHead>
            <TableRow>
              <TableCell component="th">Workflow</TableCell>
              <TableCell component="th">Execution</TableCell>
              <TableCell component="th">Status</TableCell>
              <TableCell component="th">Start Date</TableCell>
              <TableCell component="th">Stop Date</TableCell>
              <TableCell component="th">Duration</TableCell>
              <TableCell component="th">Client</TableCell>
              <TableCell component="th">Cycle</TableCell>
              <TableCell component="th">Capture</TableCell>
              <TableCell component="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyEl}>
            {
              orchestrationExecutionsData !== null ? <>
                {
                  orchestrationExecutionsData.map((execution: WorkflowOutcome, index: number) => {
                    return <ExecutionRow key={index} executionUrl={executionUrl} execution={execution} setExecutionHistory={setExecutionHistory} setExecutionDescription={setExecutionDescription} setExecutionValidation={setExecutionValidation} setSearchParams={setSearchParams} />;
                  })
                }
              </> : <TableRow>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Button onClick={onClickCopyAll}>Copy All</Button>
        <Button onClick={onClickCopyAllWithoutHeaders}>Copy All Without Headers</Button>
      </div>
    </Paper>;
}

export {
  ExecutionTable
}