 
import { Collapse, IconButton, Skeleton, Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { OrchestrationExecutionsSummaryData, setOrchestrationSummaryData, WorkflowOutcomeSummary } from '../../async/ClientConfigData';
import { InteractionStatus } from '@azure/msal-browser';
import { IToast } from './Toast';
import { toHHMMSS } from '../../util/Duration';
import { ExecutionSummaryHistoryTable } from './ExecutionSummaryHistoryTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


interface ExecutionSummaryTableProps {
  startDate: string;
  endDate: string;
  setToast: (toastProps: IToast) => void;
  orchestrationExecutionsSummaryData: OrchestrationExecutionsSummaryData | null;
  setOrchestrationExecutionsSummaryData: (orchestrationExecutionsData: OrchestrationExecutionsSummaryData) => void;
}

export const enum SubscriptionChoice {
  Execution,
  Workflow
}

interface ExecutionSummaryRowProps {
  workflowOutcomeSummary: WorkflowOutcomeSummary;
  orchestrationExecutionsSummaryData: OrchestrationExecutionsSummaryData;
}

const ExecutionSummaryRow = ({ workflowOutcomeSummary, orchestrationExecutionsSummaryData, }: ExecutionSummaryRowProps) => {
  const [open, setOpen] = useState(false);

  const clientId = parseInt(workflowOutcomeSummary.client_id);

  const foundClient = orchestrationExecutionsSummaryData.clients.find((client) => { return client.Id === clientId });

  const duration = toHHMMSS(workflowOutcomeSummary.duration)

  return <>
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>{foundClient?.Name}</TableCell>
      <TableCell>{workflowOutcomeSummary.count}</TableCell>
      <TableCell>{duration}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={4} className={open ? "" : "table-cell-closed"}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <ExecutionSummaryHistoryTable executions={workflowOutcomeSummary.history} orchestrationExecutionsSummaryData={orchestrationExecutionsSummaryData} />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
};

const ExecutionSummaryTable = ({ startDate, endDate, setToast, orchestrationExecutionsSummaryData, setOrchestrationExecutionsSummaryData }: ExecutionSummaryTableProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && orchestrationExecutionsSummaryData === null) {
      setOrchestrationSummaryData(startDate, endDate, setOrchestrationExecutionsSummaryData, setToast)
    }
  }, [startDate, endDate, isAuthenticated, inProgress, orchestrationExecutionsSummaryData, setOrchestrationExecutionsSummaryData, setToast]);

  return <>
    <TableContainer sx={{ flexShrink: 0 }} component={Paper}>
      <Table sx={{
        "& .MuiTableRow-root:hover": {
          backgroundColor: "action.hover"
        }
      }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th"></TableCell>
            <TableCell component="th">Client</TableCell>
            <TableCell component="th">Count</TableCell>
            <TableCell component="th">Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orchestrationExecutionsSummaryData !== null && orchestrationExecutionsSummaryData.workflowOutcomeSummaries !== null ? orchestrationExecutionsSummaryData.workflowOutcomeSummaries.map((workflowOutcomeSummary: WorkflowOutcomeSummary, index: number) => {
            return <ExecutionSummaryRow key={index} workflowOutcomeSummary={workflowOutcomeSummary} orchestrationExecutionsSummaryData={orchestrationExecutionsSummaryData} />;
          }) : <TableRow>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
}

export {
  ExecutionSummaryTable
}