const formatJSONString = (json: string) => {
    const obj = JSON.parse(json);
    return formatJSONObj(obj);
};

const formatJSONObj = (json: any) => {
    return JSON.stringify(json, null, 4);
};

export {
    formatJSONString,
    formatJSONObj
}