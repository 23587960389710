import { useState } from 'react';

import { Dayjs } from 'dayjs';
import { OrchestrationExecutionsSummaryData } from '../../async/ClientConfigData';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { ExecutionSummaryTable } from '../components/ExecutionSummaryTable';
import { IToast } from '../components/Toast';

interface ClientPublicationsProps {
  setToast: (toastProps: IToast) => void;
}

const ClientPublications = ({setToast}: ClientPublicationsProps) => {

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [orchestrationExecutionsSummaryData, setOrchestrationExecutionsSummaryData] = useState<OrchestrationExecutionsSummaryData | null>(null);

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    setOrchestrationExecutionsSummaryData(null);
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    setOrchestrationExecutionsSummaryData(null);
  };

  return <>
    <h1 className='fugro-h1'>DataHub - Execution History</h1>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
      <DesktopDatePicker
        label="Start Date"
        inputFormat="DD/MM/YYYY"
        value={startDate}
        onChange={handleStartDateChange}
        renderInput={(params: any) => <TextField {...params} />}
      />
      <DesktopDatePicker
        label="End Date"
        inputFormat="DD/MM/YYYY"
        value={endDate}
        onChange={handleEndDateChange}
        renderInput={(params: any) => <TextField {...params} />}
      />
      </div>
    </LocalizationProvider>
    {
      startDate !== null && endDate !== null ?
      <ExecutionSummaryTable
        startDate={startDate.format('YYYY-MM-DD')}
        endDate={endDate.format('YYYY-MM-DD')}
        orchestrationExecutionsSummaryData={orchestrationExecutionsSummaryData}
        setOrchestrationExecutionsSummaryData={setOrchestrationExecutionsSummaryData}
        setToast={setToast}
      /> : <></>
    }
  </>
};

export {
  ClientPublications
}