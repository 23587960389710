
import { Skeleton, Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetExecutionHistoryCommandOutput, HistoryEvent } from "@aws-sdk/client-sfn";
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { getAndSetExecutionHistory } from '../../async/ClientConfigData';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { IToast } from './Toast';
import { timeZone, toDateTimeFormat, toHHMMSS } from '../../util/Duration';

interface ExecutionHistoryTableProps {
  setToast: (toastProps: IToast) => void;
  executionArn: string;
  executionHistory: GetExecutionHistoryCommandOutput | null;
  setExecutionHistory: (executionHistory: GetExecutionHistoryCommandOutput) => void;
};

const hasFailureKey = (eventKey: string) => { return eventKey.toLowerCase().includes('fail') };

const eventFailed = (event: HistoryEvent) => {
  const eventKeys = Object.keys(event);
  return eventKeys.some(hasFailureKey);
};

interface EventRowProps {
  event: HistoryEvent;
  index: number;
  openEvents: number[];
  setOpenEvents: (openEvents: number[]) => void;
  executionHistory: GetExecutionHistoryCommandOutput;
  lastRowRef: RefObject<HTMLTableRowElement>;
}

const EventRow = ({ event, index, openEvents, setOpenEvents, executionHistory, lastRowRef }: EventRowProps) => {
  const prevEvent = executionHistory.events?.find((findEvent) => { return event.previousEventId === findEvent.id });
  const dt = event.timestamp !== undefined ? new Date(event.timestamp) : undefined;
  const prevDt = prevEvent !== undefined && prevEvent.timestamp !== undefined ? new Date(prevEvent.timestamp) : undefined;
  const diff = prevDt !== undefined && dt !== undefined ? toHHMMSS(dt.getTime() - prevDt.getTime()) : '';

  const name = event.stateEnteredEventDetails?.name || event.stateExitedEventDetails?.name || event.mapIterationAbortedEventDetails?.name
    || event.mapIterationFailedEventDetails?.name || event.mapIterationStartedEventDetails?.name || event.mapIterationSucceededEventDetails?.name;

  const tableRowOnClick = () => {
    if (openEvents.includes(index)) {
      const openIndex = openEvents.indexOf(index);
      const newOpenEvents = openEvents.slice(0, openIndex).concat(openEvents.slice(openIndex + 1, openEvents.length));
      setOpenEvents(newOpenEvents);
    } else {
      const newOpenEvents = openEvents.concat([index]);
      setOpenEvents(newOpenEvents);
    }
  }

  const failed = eventFailed(event);
  const failedStyle = failed ? { backgroundColor: 'error.light' } : {};

  if (index + 1 === executionHistory.events?.length) {
    return <TableRow
      ref={lastRowRef}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...failedStyle }}
      onClick={tableRowOnClick}
    >
      <TableCell>{event.id}</TableCell>
      <TableCell>{event.type}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{diff}</TableCell>
      <TableCell>{dt ? toDateTimeFormat(dt) : ''}</TableCell>
    </TableRow>;
  } else {
    return <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...failedStyle }}
      onClick={tableRowOnClick}
    >
      <TableCell>{event.id}</TableCell>
      <TableCell>{event.type}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{diff}</TableCell>
      <TableCell>{dt ? toDateTimeFormat(dt) : ''}</TableCell>
    </TableRow>;
  }
};

const getFormattedEvent = (event: HistoryEvent) => {
  const eventKeys = Object.keys(event);
  const failureKey = eventKeys.find(hasFailureKey);

  if (failureKey) {
    const anyEvent = event as any;
    const failureObj = anyEvent[failureKey];
    return {
      ...anyEvent,
      [failureKey]: {
        ...anyEvent[failureKey],
        cause: failureObj.cause
      }
    }
  } else {
    return event;
  }
}

interface EventJSONRowProps {
  event: HistoryEvent;
}

const EventJSONRow = ({ event }: EventJSONRowProps) => {
  const finalEvent = getFormattedEvent(event);

  const jsonEvent = JSON.stringify(finalEvent, null, 4);
  return <TableRow >
    <TableCell colSpan={5}>
      <pre className='fugro-code'>
        {jsonEvent}
      </pre>
    </TableCell>
  </TableRow>;
};


const ExecutionHistoryTable = ({ setToast, executionArn, executionHistory, setExecutionHistory }: ExecutionHistoryTableProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  const [loading, setLoading] = useState(false);
  const [openEvents, setOpenEvents] = useState<number[]>([]);

  const loadMoreRef = useRef<HTMLTableRowElement>(null);

  const hasMore = executionHistory !== null && executionHistory.nextToken !== undefined;

  const loadMore = useCallback(() => {
    const loadItems = (executionHistory: GetExecutionHistoryCommandOutput) => {
      const extendExecutionHistory = (newExecutionHistory: GetExecutionHistoryCommandOutput) => {
        const events = executionHistory !== null && executionHistory.events !== undefined && newExecutionHistory.events !== undefined ? executionHistory.events.concat(newExecutionHistory.events) : newExecutionHistory.events;
        const execHistory = { ...newExecutionHistory, events };
        setExecutionHistory(execHistory);
      };
      getAndSetExecutionHistory(executionArn, extendExecutionHistory, setToast, executionHistory.nextToken).then(() => {
        setLoading(false);
      });
    }

    if (isAuthenticated && inProgress === InteractionStatus.None && hasMore && loading !== true) {
      setLoading(true);
      loadItems(executionHistory)
    }
  }, [isAuthenticated, inProgress, executionArn, executionHistory, setExecutionHistory, setToast, loading, hasMore]);

  const loadStart = useCallback(() => {
    const loadItems = () => {
      getAndSetExecutionHistory(executionArn, setExecutionHistory, setToast).then(() => {
        setLoading(false);
      });
    }

    if (isAuthenticated && inProgress === InteractionStatus.None && executionHistory === null && loading !== true) {
      setLoading(true);
      loadItems();
      setOpenEvents([]);
    }
  }, [isAuthenticated, inProgress, executionArn, executionHistory, setExecutionHistory, setToast, loading]);

  useEffect(() => {
    loadStart();
  }, [loadStart]);

  const handleObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting) {
      loadMore();
    }
  }, [loadMore]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);

    const current = loadMoreRef.current;

    if (current !== null) {
      observer.observe(current);
    }

    return () => {
      if (current !== null) {
        observer.unobserve(current);
      }
    };
  }, [loadMoreRef, handleObserver]);

  const failures = executionHistory !== null && executionHistory.events !== undefined ? executionHistory.events.filter(eventFailed) : [];

  const numFailuresStr = failures !== undefined && failures.length > 0 ? `🚫 ${failures.length}` : "✅ No";
  const moreEventsStr = hasMore ? ` in the first ${executionHistory.events?.length} events (scroll down for more)` : '';

  return <>
    {executionHistory !== null ? <h3>{`${numFailuresStr} failed events found${moreEventsStr}`}</h3> : <Skeleton><h3>0 Failed events found</h3></Skeleton>}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Timestamp {`(${timeZone} Time)`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {executionHistory !== null && executionHistory.events !== undefined ? executionHistory.events.map((event: HistoryEvent, index: number) => {
            return openEvents.includes(index) ? [
              <EventRow key={index} event={event} index={index} openEvents={openEvents} setOpenEvents={setOpenEvents} executionHistory={executionHistory} lastRowRef={loadMoreRef} />,
              <EventJSONRow key={`${index}-a`} event={event} />
            ] : [
              <EventRow key={index} event={event} index={index} openEvents={openEvents} setOpenEvents={setOpenEvents} executionHistory={executionHistory} lastRowRef={loadMoreRef}/>,
            ];
          }).flat() : <TableRow>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
}


export {
  ExecutionHistoryTable
}