const PIPELINE_PUBLISH_SFN = `arn:aws:states:${process.env.REACT_APP_AWS_REGION}:${process.env.REACT_APP_AWS_ACCOUNT_ID}:stateMachine:${process.env.REACT_APP_PIPELINE_PUBLISH_SFN_NAME}`;
const PIPELINE_TPLY_SFN = `arn:aws:states:${process.env.REACT_APP_AWS_REGION}:${process.env.REACT_APP_AWS_ACCOUNT_ID}:stateMachine:${process.env.REACT_APP_PIPELINE_TPLY_SFN_NAME}`;
const PUBLISH_NAME = process.env.REACT_APP_PUBLISH_SFN_NAME || '';
const CLIENT_CONFIG_API_URL = process.env.REACT_APP_CLIENT_CONFIG_API_URL || '';
const DATAHUB_API_URL = process.env.REACT_APP_DATAHUB_API_URL || '';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
const AUTHORITY = process.env.REACT_APP_AUTHORITY as string;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL as string;

const getExecutionUrl = (stateMachineName: string, executionName: string | undefined) => {
    return `https://${process.env.REACT_APP_AWS_REGION}.console.aws.amazon.com/states/home?region=${process.env.REACT_APP_AWS_REGION}#/v2/executions/details/arn:aws:states:${process.env.REACT_APP_AWS_REGION}:${process.env.REACT_APP_AWS_ACCOUNT_ID}:execution:${stateMachineName}:${executionName}`;
};

export {
    PIPELINE_PUBLISH_SFN,
    PIPELINE_TPLY_SFN,
    PUBLISH_NAME,
    CLIENT_CONFIG_API_URL,
    DATAHUB_API_URL,
    CLIENT_ID,
    AUTHORITY,
    REDIRECT_URL,
    getExecutionUrl
}