const root = '/';
const clientPublications = 'clientpublications';
const clientRepublish = 'clientrepublish';
const pipelinePublish = 'pipelinepublish';
const subscriptions = 'subscriptions';

const getRootWithExecution = (execution: string) => {
    return `${root}?execution=${execution}`;
}

export {
    root,
    clientPublications,
    clientRepublish,
    pipelinePublish,
    getRootWithExecution,
    subscriptions
}