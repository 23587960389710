import { DescribeExecutionCommandOutput } from "@aws-sdk/client-sfn";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect } from "react";
import { getAndSetExecutionDescription, getExecutionValidation } from "../../async/ClientConfigData";
import { IToast } from "./Toast";

interface ExecutionValidationProps {
    setToast: (toastProps: IToast) => void;
    executionArn: string;
    executionValidation: any[] | null;
    setExecutionValidation: (executionHistory: any[]) => void;
    executionDescription: DescribeExecutionCommandOutput | null;
    setExecutionDescription: (executionHistory: DescribeExecutionCommandOutput) => void;
};

const ExecutionValidation = ({ setToast, executionArn, executionValidation, setExecutionValidation, executionDescription, setExecutionDescription }: ExecutionValidationProps) => {

    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && executionDescription === null) {
            getAndSetExecutionDescription(executionArn, setExecutionDescription, setToast);
        }
    }, [isAuthenticated, inProgress, executionArn, executionDescription, setExecutionDescription, setToast]);


    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None &&
             executionDescription !== null && executionValidation === null) {
            const workflowName = executionDescription.stateMachineArn?.split("stateMachine:")[1] || '';
            getExecutionValidation(workflowName, executionDescription.name || '').then((response) => {
                setExecutionValidation(response);
            });
        }
    }, [isAuthenticated, inProgress, executionArn, executionDescription, setExecutionDescription, executionValidation, setExecutionValidation, setToast]);    

    return <div className='fugro-execution-io'>
        {
            executionValidation !== null ? <>
                <div>
                    <h4>Validation</h4>
                    <pre className='fugro-code'>
                        {executionValidation.length > 0 ? executionValidation.map((validation) => {
                            return <div>
                                <p>{validation.pk} - {validation.sk}</p>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th">Condition</TableCell>
                                            <TableCell component="th">Description</TableCell>
                                            <TableCell component="th">Success Condition</TableCell>
                                            <TableCell component="th">Warn Condition</TableCell>
                                            <TableCell component="th">Count</TableCell>
                                            <TableCell component="th">Total</TableCell>
                                            <TableCell component="th">Percentage</TableCell>
                                            <TableCell component="th">Result</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {validation.results.map((result: any) => {
                                        const failed = result.outcome === 'SOFT_ERROR' || result.outcome === 'ERROR';
                                        const failedStyle = failed ? { backgroundColor: 'error.light' } : {};

                                        const warning = result.outcome === 'WARNING';
                                        const warningStyle = warning ? { backgroundColor: 'warning.light' } : {};

                                        return <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...failedStyle, ...warningStyle }}
                                        >
                                            <TableCell component="th">{result.display_name}</TableCell>
                                            <TableCell component="th">{result.description}</TableCell>
                                            <TableCell component="th">{result.success_condition}</TableCell>
                                            <TableCell component="th">{result.warn_condition}</TableCell>
                                            <TableCell component="th">{result.condition_count}</TableCell>
                                            <TableCell component="th">{result.total_count}</TableCell>
                                            <TableCell component="th">{result.condition_percent}%</TableCell>
                                            <TableCell component="th">{result.outcome}</TableCell>
                                        </TableRow>
                                    })}
                                    </TableBody>
                                </Table>
                            </div>
                        }) : <p>No Validation Results Found</p>}
                    </pre>
                </div>
            </> : <>
                <div>
                    <Skeleton><h4>Validation</h4></Skeleton>
                    <Skeleton style={{ minHeight: '3em' }} />
                </div>
            </>
        }
    </div>;
};

export {
    ExecutionValidation
};