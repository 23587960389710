import { DescribeExecutionCommandOutput } from "@aws-sdk/client-sfn";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { abortExecution, getAndSetExecutionDescription } from "../../async/ClientConfigData";
import { toDateTimeFormat, toHHMMSS } from "../../util/Duration";
import { IToast } from "./Toast";
import { msalInstance } from "../../index";
import { isDatahubAdmin } from "../../auth/Auth";

interface ExecutionDetailsProps {
    setToast: (toastProps: IToast) => void;
    executionArn: string;
    executionDescription: DescribeExecutionCommandOutput | null;
    setExecutionDescription: (executionHistory: DescribeExecutionCommandOutput | null) => void;
    publishOnClick: () => void;
};

const ExecutionDetails = ({ setToast, executionArn, executionDescription, setExecutionDescription, publishOnClick }: ExecutionDetailsProps) => {
    const account = msalInstance.getActiveAccount();
    const isAdmin = isDatahubAdmin(account);
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && executionDescription === null) {
            getAndSetExecutionDescription(executionArn, setExecutionDescription, setToast);
        }
    }, [isAuthenticated, inProgress, executionArn, executionDescription, setExecutionDescription, setToast]);

    const startDate = executionDescription !== null && executionDescription.startDate !== undefined && executionDescription.startDate !== null ? new Date(executionDescription.startDate) : undefined;
    const stopDate = executionDescription !== null && executionDescription.stopDate !== undefined && executionDescription.stopDate !== null ? new Date(executionDescription.stopDate) : undefined;
    const duration = startDate !== undefined && stopDate !== undefined ? toHHMMSS(stopDate.getTime() - startDate.getTime()) : '';

    const refreshOnClick = () => {
        setLoading(false);
        setExecutionDescription(null);
    };

    const abortOnClick = () => {
        if (executionDescription !== null && executionDescription.executionArn !== undefined) {
            abortExecution(executionDescription.executionArn).then(refreshOnClick).catch((error) => {
                setLoading(false);
                setToast({ open: true, messageType: 'error', text: 'Stop Execution Failed', error });
                console.log(error);
            });
            setLoading(true);
        }
    };

    return <div className='fugro-execution-details'>{
        executionDescription !== null ? <>
            <div>
                <div>
                    <h4>Actions</h4>
                    <div>
                        <Button onClick={refreshOnClick} disabled={!isAdmin || loading}>Refresh</Button>
                        {
                            executionDescription.status !== undefined && executionDescription.status === 'RUNNING' ?
                            <Button onClick={abortOnClick} disabled={!isAdmin || loading}>Stop Execution</Button> : <></>
                        }
                        <Button onClick={publishOnClick}>Republish</Button>
                    </div>
                </div>
                <div>
                    <h4>Execution Status</h4>
                    <p>{executionDescription.status}</p>
                </div>
                <div>
                    <h4>Execution ARN</h4>
                    <p>{executionDescription.executionArn}</p>
                </div>
            </div>
            <div>
                <div>
                    <h4>Start Time</h4>
                    <p>{startDate !== undefined ? toDateTimeFormat(startDate) : <></>}</p>
                </div>
                <div>
                    <h4>End Time</h4>
                    <p>{stopDate !== undefined ? toDateTimeFormat(stopDate) : <></>}</p>
                </div>
                <div>
                    <h4>Duration</h4>
                    <p>{duration}</p>
                </div>
            </div>
        </> : <>
            <div>
                <Skeleton><h4>Input</h4></Skeleton>
                <Skeleton style={{ minHeight: '3em' }} />
            </div>
        </>
    }
    </div>;
};

export {
    ExecutionDetails
};