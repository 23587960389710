import { useState } from 'react';

import { ExecutionTable } from '../components/ExecutionTable';
import { IToast } from '../components/Toast';
import { ExecutionHistoryTable } from '../components/ExecutionHistoryTable';
import { Box, Tabs, Tab, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { DescribeExecutionCommandOutput, GetExecutionHistoryCommandOutput } from '@aws-sdk/client-sfn';
import { ExecutionIO } from '../components/ExecutionIO';
import { ExecutionValidation } from '../components/ExecutionValidation';
import { EnqueuedWorkflowOutcome, WorkflowOutcome } from '../../async/ClientConfigData';
import { ExecutionDetails } from '../components/ExecutionDetails';
import { EnqueuedExecutionTable } from '../components/EnqueuedExecutionTable';
import { PipelinePublishDialog, PipelinePublishDialogData, getPipelinePublishDialogFunctions, initialPipelinePublishDialogState } from '../components/PipelinePublishDialog';

interface OrchestrationExecutionsProps {
  setToast: (toastProps: IToast) => void;
}

interface TabPanelProps {
  children: JSX.Element[] | JSX.Element | string;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const OrchestrationExecutions = ({ setToast }: OrchestrationExecutionsProps) => {
  const [searchParams] = useSearchParams();
  const executionQueryParam = searchParams.get("execution");

  const [orchestrationExecutionsData, setOrchestrationExecutionsData] = useState<WorkflowOutcome[] | null>(null);
  const [enqueuedOrchestrationExecutionsData, setEnqueuedOrchestrationExecutionsData] = useState<EnqueuedWorkflowOutcome[] | null>(null);
  const [executionHistory, setExecutionHistory] = useState<GetExecutionHistoryCommandOutput | null>(null);
  const [executionDescription, setExecutionDescription] = useState<DescribeExecutionCommandOutput | null>(null);
  const [executionValidation, setExecutionValidation] = useState<any[] | null>(null);
  const [publishDialogData, setPublishDialogData] = useState<PipelinePublishDialogData>(initialPipelinePublishDialogState);

  const [tabPanel, setTabPanel] = useState(0);

  const {openDialog, setValue, setRootValue, closeDialog} = getPipelinePublishDialogFunctions(publishDialogData, setPublishDialogData);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const publishOnClick = () => {
    if (executionDescription !== null) {
      const input = executionDescription?.input !== null && executionDescription?.input !== undefined ? JSON.parse(executionDescription.input) : undefined;
      if (input !== undefined) {
        openDialog(input);
      }
    }
};

  return <div className='fugro-content-body-spaced'>
      <Stack direction="column" spacing={2} className='fugro-executions-content'>
        <h1 className='fugro-h1'>DataHub - Execution History</h1>
        <EnqueuedExecutionTable setToast={setToast} enqueuedOrchestrationExecutionsData={enqueuedOrchestrationExecutionsData} setEnqueuedOrchestrationExecutionsData={setEnqueuedOrchestrationExecutionsData} />
        <ExecutionTable setToast={setToast} orchestrationExecutionsData={orchestrationExecutionsData} setOrchestrationExecutionsData={setOrchestrationExecutionsData} setExecutionHistory={setExecutionHistory} setExecutionDescription={setExecutionDescription} setExecutionValidation={setExecutionValidation} />
      </Stack>
    {
      executionQueryParam !== null ? <Box className='fugro-execution-content'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabPanel} onChange={handleChange}>
            <Tab label="Execution Details" />
            <Tab label="Input/Output" />
            <Tab label="Execution History" />
            <Tab label="Validation" />
          </Tabs>
        </Box>
        <TabPanel value={tabPanel} index={0}>
          <ExecutionDetails executionArn={executionQueryParam} executionDescription={executionDescription} setExecutionDescription={setExecutionDescription} setToast={setToast} publishOnClick={publishOnClick} />
        </TabPanel>
        <TabPanel value={tabPanel} index={1}>
          <ExecutionIO executionArn={executionQueryParam} executionDescription={executionDescription} setExecutionDescription={setExecutionDescription} setToast={setToast} />
        </TabPanel>
        <TabPanel value={tabPanel} index={2}>
          <ExecutionHistoryTable executionArn={executionQueryParam} executionHistory={executionHistory} setExecutionHistory={setExecutionHistory} setToast={setToast} />
        </TabPanel>
        <TabPanel value={tabPanel} index={3}>
          <ExecutionValidation executionArn={executionQueryParam} executionValidation={executionValidation} setExecutionValidation={setExecutionValidation} executionDescription={executionDescription} setExecutionDescription={setExecutionDescription} setToast={setToast} />
        </TabPanel>
      </Box> : <></>
    }
    <PipelinePublishDialog open={publishDialogData.open} closeDialog={closeDialog} value={publishDialogData.value} setValue={setValue} setRootValue={setRootValue} setToast={setToast} />
  </div>
};

export {
  OrchestrationExecutions
}