const pad0 = (num: number) => num.toString().padStart(2, '0');

const toHHMMSS = (milliseconds: number) => {
    const inSeconds = Math.floor(milliseconds / 1000);
    const inMinutes = Math.floor(inSeconds / 60);
    const hours = Math.floor(inMinutes / 60);

    const seconds = inSeconds % 60;
    const minutes = inMinutes % 60;

    const hoursString = pad0(hours);
    const minutesString = pad0(minutes);
    const secondsString = pad0(seconds);
    return `${hoursString}:${minutesString}:${secondsString}`;
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const toDateTimeFormat = (dateTime: Date) => {
    const seconds = dateTime.getSeconds();
    const minutes = dateTime.getMinutes();
    const hours = dateTime.getHours();
    const dayt = dateTime.getDate();
    const month = months[dateTime.getMonth()];
    const year = dateTime.getFullYear();
    return `${dayt} ${month} ${year} ${hours}:${minutes}:${seconds}`;
};

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export {
    toHHMMSS,
    toDateTimeFormat,
    timeZone
}