import { callWithAuthToken } from '../auth/AuthApiCall';
import { IToast } from '../components/components/Toast';
import { DescribeExecutionCommandOutput, GetExecutionHistoryCommandOutput } from "@aws-sdk/client-sfn";
import { CLIENT_CONFIG_API_URL, DATAHUB_API_URL } from '../util/GlobalVars';

export interface ClientData {
    Id: number; 
    Name: string;
    Code: string;
    CreateDate: string;
    UpdateDate: any;
    AuthToken: string;
    RoamesWebVersion: string;
    ApplySpanMatchVoltage: number;
    CountryCode: any;
    TimeZoneAbbr: any;
    AwsRegion: any;
    MeasurementUnits: any;
    IgnoreDefaultIntrusionZones: boolean;
    DataIsDeleted: boolean;
}

export interface CaptureData {
    Id: number;
    Name: string;
    CaptureStart: string;
    CaptureFinish: string;
    ProcessingBoundaryBayCount: number;
    ReviewBoundaryBayCount: number;
    CreateDate: string;
    UpdateDate: string;
    ParentCapture: string;
    ClientId: number;
}

export interface CampaignData {
    CaptureId: any;
    Cycle: any;
    CampaignName: string;
    CreateDate: string;
}

export interface WorkflowOutcome {
    id: number;
    execution_arn: string;
    status: string;
    created_at: string;
    start_date: string;
    stop_date: string;
    state_machine_arn: string;
    client_id: string;
    cycle: string;
    capture_id: string;
    client_name: string;
    capture_name: string;
    client_code: string;
    campaign: string;
}

export interface EnqueuedWorkflowOutcome {
    id: number;
    created_at: string;
    state_machine_arn: string;
    client_id: string;
    cycle: string;
    capture_id: string;
    client_name: string;
    capture_name: string;
    client_code: string;
    campaign: string;
    execution_order: number;
}

export interface WorkflowOutcomeSummary {
    client_id: string;
    count: number;
    duration: number;
    history: WorkflowOutcome[];
}

export interface OrchestrationExecutionsSummaryData {
    clients: ClientData[];
    captures: CaptureData[];
    workflowOutcomeSummaries: WorkflowOutcomeSummary[];
}

export interface ClientsCapturesCampaignsData {
    clients: ClientData[];
    captures: CaptureData[];
    campaigns: CampaignData[];
    publishExecutionDescriptions: WorkflowOutcome[];
}

const getPipelinePublishData = async () => {
    const clientsPromise = callWithAuthToken(`${CLIENT_CONFIG_API_URL}/clients`, true, 'GET', undefined);
    const capturesPromise = callWithAuthToken(`${CLIENT_CONFIG_API_URL}/captures`, true, 'GET', undefined);
    const campaignsPromise = callWithAuthToken(`${CLIENT_CONFIG_API_URL}/campaigns`, true, 'GET', undefined);
    const executionsPromise = callWithAuthToken(`${DATAHUB_API_URL}/workflow_outcomes/`, true, 'GET', undefined);
    return Promise.all([clientsPromise, capturesPromise, campaignsPromise, executionsPromise]);
};

const setClientsCapturesCampaignsData = (setClientsCapturesCampaigns: (clientsCapturesCampaigns: any) => void) => {
    getPipelinePublishData().then((result: any[][]) => {
        const clientsCapturesCampaigns = {
            clients: result[0][0],
            captures: result[1][0],
            campaigns: result[2][0],
            publishExecutionDescriptions: result[3]
        };
        setClientsCapturesCampaigns(clientsCapturesCampaigns)
    });
};

const getWorkflowOutcomes = async () => {
    return callWithAuthToken(`${DATAHUB_API_URL}/workflow_outcomes/`, true, 'GET', undefined);
};

const getWorkflowOutcomesSummary = async (startDate: string, endDate: string) => {
    const clientsPromise = callWithAuthToken(`${CLIENT_CONFIG_API_URL}/clients`, true, 'GET', undefined);
    const capturesPromise = callWithAuthToken(`${CLIENT_CONFIG_API_URL}/captures`, true, 'GET', undefined);
    const executionsPromise = callWithAuthToken(`${DATAHUB_API_URL}/workflow_outcomes_by_dates/?startDate=${startDate}&endDate=${endDate}`, true, 'GET', undefined);
    return Promise.all([clientsPromise, capturesPromise, executionsPromise]);
};

const getExecutionDescription = (executionArn: string) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/executions/${executionArn}`, true, 'GET', undefined);
};

const getLayers = () => {
    return callWithAuthToken(`${DATAHUB_API_URL}/layers/`, true, 'GET', undefined);
};

const abortExecution = (executionArn: string) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/executions/${executionArn}`, true, 'PUT', JSON.stringify({
       action: 'ABORT' 
    }));
};

const deleteEnqueuedExecution = (id: any) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/enqueued_workflow_outcomes/${id}`, true, 'DELETE', undefined);
};

const swapEnqueuedExecutions = (execution1: EnqueuedWorkflowOutcome, execution2: EnqueuedWorkflowOutcome) => {
    const body = {
        id1: execution1.id,
        execution_order1: execution1.execution_order,
        id2: execution2.id,
        execution_order2: execution2.execution_order
    }
    return callWithAuthToken(`${DATAHUB_API_URL}/enqueued_workflow_outcomes/swap`, true, 'POST', JSON.stringify(body));
};

const getExecutionValidation = (stepFunctionName: string, executionName: string) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/workflows/${stepFunctionName}/executions/${executionName}/dq/test-results`, true, 'GET', undefined);
};

const getExecutionHistory = (executionArn: string, nextToken?: string) => {
    const token = nextToken !== undefined ? { nextToken } : undefined;
    const queryParams = new URLSearchParams(token);
    return callWithAuthToken(`${DATAHUB_API_URL}/executions/${executionArn}/history?${queryParams}`, true, 'GET', undefined);
};

const invokeNamed = (stateMachineArn: string, body: any) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/workflows/${stateMachineArn}/named_executions`, true, 'POST', JSON.stringify(body));
};

const setOrchestrationData = (setData: (data: WorkflowOutcome[]) => void, setToast: (toastProps: IToast) => void) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/workflow_outcomes/`, true, 'GET', undefined).then((results) => setData(results))
    .catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Executions Failed', error });
        console.log(error);
    });
};

const setEnqueuedOrchestrationData = (setData: (data: EnqueuedWorkflowOutcome[]) => void, setToast: (toastProps: IToast) => void) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/enqueued_workflow_outcomes/`, true, 'GET', undefined)
    .then((results) => setData(results))
    .catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Executions Failed', error });
        console.log(error);
    });
};

const setOrchestrationSummaryData = (startDate: string, endDate: string, setData: (data: OrchestrationExecutionsSummaryData) => void, setToast: (toastProps: IToast) => void) => {
    getWorkflowOutcomesSummary(startDate, endDate).then((results) => {
        setData({
            clients: results[0][0],
            captures: results[1][0],
            workflowOutcomeSummaries: results[2]
        });
    }).catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Execution Summaries Failed', error });
        console.log(error);
    });
};


const getAndSetExecutionHistory = (executionArn: string, setExecutionHistory: (clientConfigData: GetExecutionHistoryCommandOutput) => void, setToast: (toastProps: IToast) => void, nextToken?: string) => {
    return getExecutionHistory(executionArn, nextToken).then((results) => {
        setExecutionHistory(results);
    }).catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Execution History Failed', error });
        console.log(error);
    });
};

const getAndSetExecutionDescription = (executionArn: string, setExecutionDescription: (clientConfigData: DescribeExecutionCommandOutput) => void, setToast: (toastProps: IToast) => void) => {
    return getExecutionDescription(executionArn).then((results) => {
        setExecutionDescription(results);
    }).catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Execution Description Failed', error });
        console.log(error);
    });
};

const getAndSetLayers = (setLayers: (layers: any) => void, setToast: (toastProps: IToast) => void) => {
    return getLayers().then((results) => {
        const parsed = JSON.parse(results);
        setLayers(parsed);
    }).catch((error) => {
        setToast({ open: true, messageType: 'error', text: 'Retrieval of Layers Failed', error });
        console.log(error);
    });
};

const subscribeToExecutionChanges = (data: any) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/subscribe`, true, 'POST', JSON.stringify(data));
};

const unsubscribeToExecutionChanges = (data: any) => {
    return callWithAuthToken(`${DATAHUB_API_URL}/subscribe`, true, 'DELETE', JSON.stringify(data));
};

export {
    setClientsCapturesCampaignsData,
    getWorkflowOutcomes,
    getWorkflowOutcomesSummary,
    getExecutionValidation,
    abortExecution,
    deleteEnqueuedExecution,
    swapEnqueuedExecutions,
    invokeNamed,
    setOrchestrationData,
    setEnqueuedOrchestrationData,
    setOrchestrationSummaryData,
    getAndSetExecutionHistory,
    getAndSetExecutionDescription,
    getAndSetLayers,
    subscribeToExecutionChanges,
    unsubscribeToExecutionChanges
}