import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export interface IToast {
  messageType: 'success' | 'error' | 'warning' | 'info';
  text: string;
  open: boolean;
  error?: Error;
}

interface ToastProps {
  toastAttrs: IToast;
  setToast: (arg: IToast) => void;
}

const Toast = (props: ToastProps) => {
  const onClose = () => {
    props.setToast({ open: false, text: '', messageType: 'info' });
  };

  if (props.toastAttrs.open === false) return <></>

  return (
    <div>
      <Snackbar
        open={props.toastAttrs.open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert sx={{ width: '100%' }} severity={props.toastAttrs.messageType}>{props.toastAttrs.text}{
          props.toastAttrs.error !== undefined ? <>{` - Error Message: ${props.toastAttrs.error.message}`}</> : <></>
        }</Alert>
      </Snackbar>
    </div>
  );
}

export { Toast }
