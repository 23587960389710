import { IToast } from '../components/Toast';
import { SubscriptionsTable } from '../components/SubscriptionsTable';

interface OrchestrationExecutionsProps {
  setToast: (toastProps: IToast) => void;
}

const Subscriptions = ({ setToast }: OrchestrationExecutionsProps) => {
  return <>
    <h1 className='fugro-h1'>Subscriptions</h1>
    <div>
      <SubscriptionsTable setToast={setToast}  />
    </div>
  </>;
};

export {
  Subscriptions
}