import { DescribeExecutionCommandOutput } from "@aws-sdk/client-sfn";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import { getAndSetExecutionDescription } from "../../async/ClientConfigData";
import { formatJSONString } from "../../util/Json";
import { IToast } from "./Toast";

interface ExecutionIOProps {
    setToast: (toastProps: IToast) => void;
    executionArn: string;
    executionDescription: DescribeExecutionCommandOutput | null;
    setExecutionDescription: (executionHistory: DescribeExecutionCommandOutput) => void;
};

const ExecutionIO = ({ setToast, executionArn, executionDescription, setExecutionDescription }: ExecutionIOProps) => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && executionDescription === null) {
            getAndSetExecutionDescription(executionArn, setExecutionDescription, setToast);
        }
    }, [isAuthenticated, inProgress, executionArn, executionDescription, setExecutionDescription, setToast]);

    return <div className='fugro-execution-io'>{
        executionDescription !== null ? <>
            {
                executionDescription.input !== undefined ? <div>
                    <h4>Input</h4>
                    <pre className='fugro-code'>
                        {formatJSONString(executionDescription.input)}
                    </pre>
                </div> : <></>
            }
            {
                executionDescription.output !== undefined ? <div>
                    <h4>Output</h4>
                    <pre className='fugro-code'>
                        {formatJSONString(executionDescription.output)}
                    </pre>
                </div> : <></>
            } </> : <>
            <div>
                <Skeleton><h4>Input</h4></Skeleton>
                <Skeleton style={{minHeight: '3em'}}/>
            </div>
            <div>
                <Skeleton><h4>Output</h4></Skeleton>
                <Skeleton style={{minHeight: '3em'}}/>
            </div>
        </>
    }
    </div>;
};

export {
    ExecutionIO
};