import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useMemo, useState } from 'react';
import { ClientsCapturesCampaignsData, setClientsCapturesCampaignsData } from '../../async/ClientConfigData';
import { InteractionStatus } from '@azure/msal-browser';
import { Skeleton } from '@mui/material';

import { IToast } from '../components/Toast';
import { PipelinePublishTable } from '../components/PipelinePublishTable';
import { PipelinePublishDialog, PipelinePublishDialogData, getPipelinePublishDialogFunctions, initialPipelinePublishDialogState } from '../components/PipelinePublishDialog';

const initialState = null;

interface ClientConfigProps {
    setToast: (toastProps: IToast) => void;
}

const PipelinePublish = ({ setToast }: ClientConfigProps) => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    const [clientsCampaignsCaptures, setClientsCampaignsCaptures] = useState<ClientsCapturesCampaignsData | null>(initialState);
    const [publishDialogData, setPublishDialogData] = useState<PipelinePublishDialogData>(initialPipelinePublishDialogState);

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && clientsCampaignsCaptures === null) {
            setClientsCapturesCampaignsData(setClientsCampaignsCaptures);
        }
    }, [isAuthenticated, inProgress, clientsCampaignsCaptures, setToast]);

    const {openDialog, setValue, setRootValue, closeDialog} = getPipelinePublishDialogFunctions(publishDialogData, setPublishDialogData);

    const memoTable = useMemo(() => clientsCampaignsCaptures !== null ? 
    <PipelinePublishTable clientsCampaignsCaptures={clientsCampaignsCaptures} openDialog={openDialog} /> : <Skeleton width={300} />,
    // openDialog is complained about here, but adding it to the dependency array will cause slow rerenders every time we change something in the dialog data.
    // eslint-disable-next-line
    [clientsCampaignsCaptures]);

    return (
        <>
            <h1 className='fugro-h1'>Client Publications</h1>
            {memoTable}
            <PipelinePublishDialog open={publishDialogData.open} closeDialog={closeDialog} value={publishDialogData.value} setValue={setValue} setRootValue={setRootValue} setToast={setToast} />
        </>
    );
}

export {
    PipelinePublish
};
